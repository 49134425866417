@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  transition: background-color 1s ease, color 1s ease-in;
}

body {
  margin: 0;
  font-family: 'Montserrat', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400; /* Light version */
}

.logo-path {
  fill: black;
}

.dark .logo-path {
  fill: white;
}

@keyframes rotateLogo {
  from {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

.rotate-on-change {
  animation: rotateLogo 2s ease-in-out;
}

.fade-in {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-in-visible {
  opacity: 1;
  transform: translateY(0);
}

.stack-card {
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.stacked-visible {
  opacity: 1;
  transform: translateY(0);
}


.perspective {
  perspective: 1000px; 
  perspective-origin: center;
}

.flip-card {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.flipped {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  pointer-events: auto;
  z-index: 10;
}

.flip-card-back {
  transform: rotateY(180deg);
}

@media (max-width: 768px) {
  .flip-card {
    transition: transform 0.5s ease-in-out;
  }

  .flip-card-front,
  .flip-card-back {
    padding: 1rem; 
  }

  .progress-bar-container {
    height: 2px; 
  }
}


button {
  cursor: pointer;
  outline: none;
  z-index: 15; 
}

button:focus {
  outline: none;
}

button:active {
  transform: scale(0.95); 
}